import React, { Component } from "react";

import { Link } from "react-router-dom";
import terms from "../../images/terms-banner.jpg";

function TermsConditionBangla(props) {
  return (
    <div className="pb-10 container mx-auto text-black bangla-font">
      <div className="row mt-5 mb-5">
        <img src={terms} width="100%" height="100%" alt="" />
        <h1 className="text-center text-4xl font-semibold mt-5">
        শর্তাবলী
        </h1>
        <br />
      </div>
      <div className="mx-10 flex flex-col text-base gap-3 ">
        <p>
        ফ্রাই ফ্রেঞ্জি ওয়েবসাইটে স্বাগতম। এই নিয়ম ও শর্তাবলী আমাদের ওয়েবসাইট ব্যবহার করার নিয়ম ও প্রবিধানের রূপরেখা দেয়। এই ওয়েবসাইটটি অ্যাক্সেস করার মাধ্যমে, আমরা ধরে নিচ্ছি যে আপনি এই শর্তাবলী সম্পূর্ণরূপে গ্রহণ করেছেন। আপনি যদি নীচে উল্লিখিত সমস্ত শর্তাবলীতে সম্মত না হন তবে ফ্রাই ফ্রেঞ্জি ওয়েবসাইটটি ব্যবহার করা চালিয়ে যাবেন না।
        </p>
        <p>
          <span className="font-bold">১.</span> 'ফ্রাই ফ্রেঞ্জি' বা 'আমাদের' বা 'আমরা' শব্দটি ওয়েবসাইটের মালিককে বোঝায়। 'আপনি' শব্দটি আমাদের ওয়েবসাইটের ব্যবহারকারী বা দর্শককে বোঝায়।
        </p>
        <p>
          {" "}
          <span className="font-bold">২.</span> এই ওয়েবসাইটের বিষয়বস্তু আপনার সাধারণ তথ্য এবং শুধুমাত্র ব্যবহারের জন্য। এটা নোটিশ ছাড়াই পরিবর্তন সাপেক্ষে।
        </p>
        <p>
          <span className="font-bold">৩.</span> এই ওয়েবসাইটটি ব্রাউজিং পছন্দগুলি নিরীক্ষণ করতে কুকিজ ব্যবহার করে৷ এই ওয়েবসাইট ব্যবহার করে, আপনি আমাদের গোপনীয়তা নীতি অনুসারে কুকিজ ব্যবহারে সম্মত হন।
        </p>
        <p>
          <span className="font-bold">৪.</span> আমরা বা কোন তৃতীয় পক্ষ কোন নির্দিষ্ট উদ্দেশ্যে এই ওয়েবসাইটে পাওয়া বা দেওয়া তথ্য এবং উপকরণগুলির যথার্থতা, সময়োপযোগীতা, কর্মক্ষমতা, সম্পূর্ণতা বা উপযুক্ততা হিসাবে কোন ওয়ারেন্টি বা গ্যারান্টি প্রদান করি না। আপনি স্বীকার করেন যে এই ধরনের তথ্য এবং উপকরণগুলিতে ভুল বা ত্রুটি থাকতে পারে এবং আমরা আইন দ্বারা অনুমোদিত সম্পূর্ণ পরিমাণে এই জাতীয় ভুল বা ত্রুটির জন্য স্পষ্টভাবে দায়বদ্ধতা বাদ দিই।
        </p>
        <p>
          <span className="font-bold">৫.</span> এই ওয়েবসাইটে আপনার কোন তথ্য বা উপকরণ ব্যবহার সম্পূর্ণরূপে আপনার নিজের ঝুঁকিতে, যার জন্য আমরা দায়ী থাকব না। এই ওয়েবসাইটের মাধ্যমে উপলব্ধ যে কোনও পণ্য, পরিষেবা বা তথ্য আপনার নির্দিষ্ট প্রয়োজনীয়তা পূরণ করে তা নিশ্চিত করা আপনার দায়িত্ব।
        </p>
        <p>
          <span className="font-bold">৬.</span> এই ওয়েবসাইটটিতে এমন উপাদান রয়েছে যা আমাদের মালিকানাধীন বা লাইসেন্সপ্রাপ্ত। এই উপাদান অন্তর্ভুক্ত, কিন্তু সীমাবদ্ধ নয়, নকশা, বিন্যাস, চেহারা, চেহারা, এবং গ্রাফিক্স. পূর্বে লিখিত সম্মতি না পাওয়া পর্যন্ত প্রজনন নিষিদ্ধ।
        </p>
        <p>
          {" "}
          <span className="font-bold">৭.</span> এই ওয়েবসাইটে পুনরুত্পাদিত সমস্ত ট্রেডমার্ক, যেগুলি অপারেটরের সম্পত্তি নয় বা লাইসেন্সপ্রাপ্ত নয়, ওয়েবসাইটে স্বীকৃত।
        </p>
        <p>
          <span className="font-bold">৮.</span> এই ওয়েবসাইটের অননুমোদিত ব্যবহার ক্ষতির জন্য একটি দাবির জন্ম দিতে পারে এবং/অথবা একটি ফৌজদারি অপরাধ হতে পারে।
        </p>
        <p>
          <span className="font-bold">৯.</span> সময়ে সময়ে, এই ওয়েবসাইটটি অন্যান্য ওয়েবসাইটের লিঙ্কও অন্তর্ভুক্ত করতে পারে। এই লিঙ্কগুলি আরও তথ্য প্রদান করার জন্য আপনার সুবিধার জন্য প্রদান করা হয়. তারা বোঝায় না যে আমরা ওয়েবসাইট(গুলি) অনুমোদন করি। লিঙ্কযুক্ত ওয়েবসাইট(গুলি) এর বিষয়বস্তুর জন্য আমাদের কোন দায়িত্ব নেই।
        </p>
        <p>
          <span className="font-bold">১০.</span> আপনার এই ওয়েবসাইটের ব্যবহার এবং ওয়েবসাইটটির এই ধরনের ব্যবহারের ফলে উদ্ভূত যেকোনো বিরোধ বাংলাদেশের আইনের অধীন।
        </p>
      </div>
    </div>
  );
}

export default TermsConditionBangla;
